import classNames from 'classnames';
import Link from 'next/link';
import {type FC, type ReactNode} from 'react';

type Props = {
  href?: string;
  label: string;
  selected?: boolean;
  icon?: ReactNode;
  notification?: number;
  onClick?: () => void;
  className?: string;
};

export const MenuItem: FC<Props> = ({
  href,
  label,
  selected,
  icon,
  notification = 0,
  onClick,
  className,
}) => {
  const linkClassNames = classNames('hover:font-bold flex items-center gap-4', {
    'text-primary-1': !selected,
    'text-black font-bold': selected,
  });

  return (
    <div className={classNames(className)}>
      {href ? (
        <Link href={href} className={linkClassNames} onClick={onClick}>
          {icon}
          {label}
          {notification > 0 && (
            <div className="rounded bg-notification px-4 text-sm text-white">
              {notification}
            </div>
          )}
        </Link>
      ) : (
        <span
          className={classNames('cursor-pointer', linkClassNames)}
          onClick={onClick}
        >
          {icon}
          {label}
          {notification > 0 && (
            <div className="rounded bg-notification px-4 text-sm text-white">
              {notification}
            </div>
          )}
        </span>
      )}
    </div>
  );
};
