export type SingleSizeArray = [number, number];
export type NamedSize = 'fluid' | ['fluid'];

export type SingleSize = SingleSizeArray | NamedSize;
export type MultiSize = SingleSize[];
export type GeneralSize = SingleSize | MultiSize;

/**
 * Google Ad Managerで定義されている広告の名前
 */
export enum GoogleAdBannerName {
  A_HEADER = 'a_header',
  A_TOP_PAGE_1 = 'a_top_page_1',
  A_TOP_PAGE_2 = 'a_top_page_2',
  B_RECTANGLE = 'b_rectangle',
  B_RECTANGLE_BOTTOM = 'b_rectangle_bottom',
  C_OVER_POST = 'c_over_post',
  C_UNDER_POST = 'c_under_post',
  D_FEED_LEFT = 'd_feed_left',
  D_FEED_LEFT_2 = 'd_feed_left_2',
  D_FEED_LEFT_SMALL = 'd_feed_left_small',
  E_FEED_TOP = 'e_feed_top',
  TEXT_1 = 'text_1',
  TEXT_2 = 'text_2',
  TEXT_3 = 'text_3',
  TOP_SPECIAL = 'top_special',
  H_COLUMN_TOP = 'h_column_top',
  RICH_DESKTOP_HEADER = 'rich_desktop_header',
  RICH_DESKTOP_BODY = 'rich_desktop_body',
  QUIT_PC_LEFT = 'quit_pc_left',
  QUIT_PC_RIGHT = 'quit_pc_right',
  QUIT_PC_CENTER_1 = 'quit_pc_center_1',
  QUIT_PC_CENTER_2 = 'quit_pc_center_2',
  F_SMARTPHONE_BOTTOM = 'f_smartphone_bottom',
  F_SMARTPHONE_IMAGE_1 = 'f_smartphone_image_1',
  F_SMARTPHONE_IMAGE_2 = 'f_smartphone_image_2',
  QUIT_SMARTPHONE_1 = 'quit_smartphone_1',
  QUIT_SMARTPHONE_2 = 'quit_smartphone_2',
}

/**
 *Google Ad Managerで定義されている広告のIDとサイズ
 */
type GoogleAdBannerMap = {
  [key in GoogleAdBannerName]: {
    size: GeneralSize;
    id: string;
  };
};
export const GoogleAdBannerMap: GoogleAdBannerMap = {
  [GoogleAdBannerName.A_HEADER]: {
    size: [468, 60],
    id: 'div-gpt-ad-1362901364947-0',
  },
  [GoogleAdBannerName.A_TOP_PAGE_1]: {
    size: [624, 80],
    id: 'div-gpt-ad-1398765075346-0',
  },
  [GoogleAdBannerName.A_TOP_PAGE_2]: {
    size: [624, 80],
    id: 'div-gpt-ad-1398765075346-1',
  },
  [GoogleAdBannerName.B_RECTANGLE]: {
    size: [300, 250],
    id: 'div-gpt-ad-1362901364947-1',
  },
  [GoogleAdBannerName.B_RECTANGLE_BOTTOM]: {
    size: [300, 250],
    id: 'div-gpt-ad-1362901364947-2',
  },
  [GoogleAdBannerName.C_OVER_POST]: {
    size: [468, 60],
    id: 'div-gpt-ad-1432029859612-3',
  },
  [GoogleAdBannerName.C_UNDER_POST]: {
    size: [468, 60],
    id: 'div-gpt-ad-1432029859612-4',
  },
  [GoogleAdBannerName.D_FEED_LEFT]: {
    size: [158, 240],
    id: 'div-gpt-ad-1432029859612-5',
  },
  [GoogleAdBannerName.D_FEED_LEFT_2]: {
    size: [158, 240],
    id: 'div-gpt-ad-1432029859612-6',
  },
  [GoogleAdBannerName.D_FEED_LEFT_SMALL]: {
    size: [200, 200],
    id: 'div-gpt-ad-1432029859612-7',
  },
  [GoogleAdBannerName.E_FEED_TOP]: {
    size: [468, 60],
    id: 'div-gpt-ad-1432029859612-8',
  },
  [GoogleAdBannerName.TEXT_1]: {
    size: [350, 18],
    id: 'div-gpt-ad-1432029859612-0',
  },
  [GoogleAdBannerName.TEXT_2]: {
    size: [350, 18],
    id: 'div-gpt-ad-1432029859612-1',
  },
  [GoogleAdBannerName.TEXT_3]: {
    size: [350, 18],
    id: 'div-gpt-ad-1432029859612-2',
  },
  [GoogleAdBannerName.TOP_SPECIAL]: {
    size: [619, 80],
    id: 'div-gpt-ad-1362901364947-12',
  },
  [GoogleAdBannerName.H_COLUMN_TOP]: {
    size: [619, 80],
    id: 'div-gpt-ad-1383876344119-0',
  },
  [GoogleAdBannerName.RICH_DESKTOP_HEADER]: {
    size: [1200, 152],
    id: 'div-gpt-ad-1505444218823-0',
  },
  [GoogleAdBannerName.RICH_DESKTOP_BODY]: {
    size: [664, 384],
    id: 'div-gpt-ad-1505444218823-1',
  },
  [GoogleAdBannerName.QUIT_PC_LEFT]: {
    size: [200, 200],
    id: 'div-gpt-ad-1507101013097-0',
  },
  [GoogleAdBannerName.QUIT_PC_RIGHT]: {
    size: [300, 250],
    id: 'div-gpt-ad-1507101050892-0',
  },
  [GoogleAdBannerName.QUIT_PC_CENTER_1]: {
    size: [624, 80],
    id: 'div-gpt-ad-1507711667401-0',
  },
  [GoogleAdBannerName.QUIT_PC_CENTER_2]: {
    size: [624, 80],
    id: 'div-gpt-ad-1507711818191-0',
  },
  [GoogleAdBannerName.F_SMARTPHONE_BOTTOM]: {
    size: [320, 50],
    id: 'div-gpt-ad-1362904182581-0',
  },
  [GoogleAdBannerName.F_SMARTPHONE_IMAGE_1]: {
    size: [320, 50],
    id: 'div-gpt-ad-1362904182581-3',
  },
  [GoogleAdBannerName.F_SMARTPHONE_IMAGE_2]: {
    size: [320, 50],
    id: 'div-gpt-ad-1362904182581-4',
  },
  [GoogleAdBannerName.QUIT_SMARTPHONE_1]: {
    size: [320, 50],
    id: 'div-gpt-ad-1507711954905-0',
  },
  [GoogleAdBannerName.QUIT_SMARTPHONE_2]: {
    size: [320, 50],
    id: 'div-gpt-ad-1507711987282-0',
  },
};

export const GOOGLE_AD_MANAGER_URL = '/43051624/';
