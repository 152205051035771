import type {FC, ReactNode} from 'react';

type Props = {
  title: string;
  children: ReactNode;
};

export const MenuGroup: FC<Props> = ({title, children}) => {
  return (
    <div className="flex flex-col">
      <div className="bg-[#CFD0D7] px-12 py-8 font-bold text-[#1e83b9]">
        {title}
      </div>
      <div className="flex flex-col gap-4 bg-white p-12">{children}</div>
    </div>
  );
};
