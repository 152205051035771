const isSmartPhone = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  const ua = window.navigator.userAgent;
  if (/iPhone|Android.+Mobile/.test(ua)) {
    return true;
  }

  return false;
};

export default isSmartPhone;
