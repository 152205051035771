import {z} from 'zod';

export const responseSchema = z.object({
  favoritedCompany: z.number(),
});

export const NotificationSchema = z.object({
  id: z.number(),
  title: z.string(),
  body: z.string(),
  detailURL: z.string(),
  isUnread: z.boolean(),
  eventType: z.string(),
  createdAt: z.string(),
});

export const GetNotificationsResponseSchema = z.object({
  totalCount: z.number(),
  nextPage: z.number(),
  notifications: z.array(NotificationSchema),
});

export type GetNotificationResponseType = z.infer<typeof NotificationSchema>;

export type GetNotificationsResponseType = z.infer<
  typeof GetNotificationsResponseSchema
>;
