import classNames from 'classnames';
import Link from 'next/link';
import type {FC} from 'react';

type Props = {
  href: string;
  label: string;
  className?: string;
};

export const MenuButtonItem: FC<Props> = ({href, label, className}) => {
  return (
    <Link
      href={href}
      className={classNames(
        'border border-primary-1 rounded p-4 text-center text-primary-1',
        className,
      )}
    >
      {label}
    </Link>
  );
};
